import React from 'react'
import Helmet from 'react-helmet'

import { PageLayout } from 'layouts/PageLayout'
import { ContactForm } from '../components/contact/ContactForm'

export default () => {
  return (
    <PageLayout>
      <Helmet title={'Contact | Floating Points'} />

      <ContactForm />

    </PageLayout>
  )
}

